import request from '../request';

export default {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    setBanners(state, payload) {
      if (typeof payload.data.banners !== 'undefined') {
        const d = JSON.parse(JSON.stringify(payload.data.banners));
        Object.keys(d).forEach((k) => {
          d[k].forEach((l, m) => {
            if (
              typeof payload.data.banners[k][m].image !== 'undefined' &&
              payload.data.banners[k][m].image !== null &&
              payload.data.banners[k][m].image !== ''
            ) {
              d[k][m].image = payload.data.banners[k][m].image.replace(
                `${payload.publicURL}%WIDTH%/`,
                ''
              );
            } else {
              delete d[k][m].image;
            }
            if (
              typeof payload.data.banners[k][m].image_mobile !== 'undefined' &&
              payload.data.banners[k][m].image_mobile !== null &&
              payload.data.banners[k][m].image_mobile !== ''
            ) {
              d[k][m].image_mobile = payload.data.banners[k][m].image_mobile.replace(
                `${payload.publicURL}%WIDTH%/`,
                ''
              );
            } else {
              delete d[k][m].image_mobile;
            }
          });
        });
        state.data = d;
      }
    },
  },
  actions: {
    getBanners(context) {
      return new Promise((resolve, reject) => {
        const { app } = context.rootState;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.STATIC_CONTENT}`;
        const type = 'GET';
        const publicURL = context.rootState.app.API_PUBLIC_URL;
        request.make(
          {
            url,
            type,
          },
          (reqErr, res) => {
            if (res.status === 200 && typeof res.body.data !== 'undefined') {
              context.commit('setBanners', { data: res.body.data, publicURL });
              resolve();
            }
            reject();
          }
        );
      });
    },
  },
};
