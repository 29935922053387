function isFbPixelPresent() {
  return eval(import.meta.env.VITE_HAS_FB_PIXEL) && typeof window.fbq !== 'undefined'; // eslint-disable-line
}

function isBingPresent() {
  return eval(import.meta.env.VITE_HAS_BING_UET) && typeof window.uetq !== 'undefined'; // eslint-disable-line
}

// Save referrer
if (document.referrer !== '') {
  if (
    !(document.referrer.includes('paypal.com') || document.referrer.includes('epayworlwide.com'))
  ) {
    window.sessionStorage.setItem('referrer', document.referrer);
  }
}

const analytics = {
  addTicketToCart(p) {
    if (typeof window.vue.$gtm !== 'undefined') {
      if (typeof window.vue.$gtm.trackEvent !== 'undefined') {
        const referrer = window.sessionStorage.getItem('referrer');
        window.vue.$gtm.trackEvent({ originalReferrer: referrer });
        window.vue.$gtm.trackEvent({
          event: 'addToCart',
          action: 'click',
          ecommerce: {
            currencyCode: import.meta.env.VITE_CURRENCY,
            add: {
              // 'add' actionFieldObject measures.
              products: [
                {
                  //  adding a product to a shopping cart.
                  name: p.name,
                  id: p.id,
                  price: (parseInt(p.price, 10) / 100).toFixed(2),
                  category: 'Ticket',
                  // variant: 'Gray',
                  quantity: p.totalCount,
                },
              ],
            },
          },
        });
      }
    }
    if (isFbPixelPresent()) {
      window.fbq('track', 'AddToCart', {
        content_ids: [p.id],
        content_name: p.name,
      });
    }
  },
  removeTicketFromCart(p) {
    if (typeof window.vue.$gtm !== 'undefined') {
      const referrer = window.sessionStorage.getItem('referrer');
      window.vue.$gtm.trackEvent({ originalReferrer: referrer });
      window.vue.$gtm.trackEvent({
        event: 'removeFromCart',
        action: 'click',
        ecommerce: {
          currencyCode: import.meta.env.VITE_CURRENCY,
          remove: {
            products: [
              {
                name: p.name,
                id: p.id,
                price: (parseInt(p.price, 10) / 100).toFixed(2),
                category: 'Ticket',
                // variant: 'Gray',
                quantity: p.totalCount,
              },
            ],
          },
        },
      });
    }
  },
  proceedToCheckout(cart, paymentType, step) {
    if (typeof window.vue.$gtm !== 'undefined') {
      if (typeof window.vue.$gtm.trackEvent !== 'undefined') {
        const referrer = window.sessionStorage.getItem('referrer');
        window.vue.$gtm.trackEvent({ originalReferrer: referrer });
        const products = [];
        cart.forEach((p) => {
          if (p.type === 'ticket') {
            products.push({
              name: p.name,
              id: p.id,
              price: (parseInt(p.price, 10) / 100).toFixed(2),
              category: 'Ticket',
              // variant: 'Gray',
              quantity: p.totalCount,
            });
          }
        });
        window.vue.$gtm.trackEvent({
          event: 'checkout',
          action: 'click',
          ecommerce: {
            checkout: {
              actionField: {
                step,
                option: paymentType,
              },
              products,
            },
          },
        });
      }
    }
    if (isFbPixelPresent()) {
      window.fbq('track', 'InitiateCheckout');
    }
  },
  makePurchase(cart, cartId, total, tax) {
    if (typeof window.vue.$gtm !== 'undefined') {
      if (typeof window.vue.$gtm.trackEvent !== 'undefined') {
        const referrer = window.sessionStorage.getItem('referrer');
        window.vue.$gtm.trackEvent({ originalReferrer: referrer });
        window.vue.$gtm.trackEvent({ totalPrice: (parseInt(total, 10) / 100).toFixed(2) });
        const products = [];
        cart.forEach((p) => {
          if (p.type === 'ticket') {
            products.push({
              name: p.name,
              id: p.id,
              price: (parseInt(p.price, 10) / 100).toFixed(2),
              category: 'Ticket',
              // variant: 'Gray',
              quantity: p.totalCount,
            });
          }
        });
        window.vue.$gtm.trackEvent({
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: cartId, // Transaction ID. Required for purchases and refunds.
                // 'affiliation': 'Online Store',
                revenue: (parseInt(total, 10) / 100).toFixed(2),
                // Total transaction value (incl. tax and shipping)
                tax,
                // shipping: '5.99',
                // 'coupon': 'SUMMER_SALE',
              },
              products,
            },
          },
        });
      }
    }
    if (isFbPixelPresent()) {
      const pIds = [];
      cart.forEach((p) => {
        pIds.push(p.id);
      });
      window.fbq('track', 'Purchase', {
        value: (parseInt(total, 10) / 100).toFixed(2),
        currency: import.meta.env.VITE_CURRENCY,
        content_ids: pIds,
        content_name: 'Tickets',
      });
    }
    if (isBingPresent()) {
      window.uetq.push('event', 'Purchase', {
        event_category: 'Ticketshop',
        event_label: 'Ticket',
        event_value: cart.length,
        revenue_value: (parseInt(total, 10) / 100).toFixed(2),
        currency: import.meta.env.VITE_CURRENCY,
      });
    }
  },
};

export default analytics;
