import request from '../request';

let persistentCustomer = {};
const persistentCustomerString = window.sessionStorage.getItem('customer');
if (persistentCustomerString !== null) {
  persistentCustomer = JSON.parse(persistentCustomerString);
}

export default {
  namespaced: true,
  state: {
    data: persistentCustomer,
  },
  mutations: {
    set(state, data) {
      state.data = data;
    },
    clear(state) {
      state.data = {};
    },
  },
  actions: {
    get(context, jwt) {
      const { app } = context.rootState;
      const url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.CUSTOMER_DATA}`;
      const type = 'GET';
      const data = { _: jwt };

      request.make(
        {
          url,
          type,
          data,
        },
        (err, res) => {
          if (res.status === 200) {
            context.commit('set', res.body.data);
            window.sessionStorage.setItem('customer', JSON.stringify(res.body.data));
            context.dispatch('user/setFormFromCustomerData', null, { root: true });
            context.dispatch('shipping/setFormFromCustomerData', null, { root: true });
          }
        }
      );
    },
    setSavedCustomer(context) {
      const value = window.sessionStorage.getItem('customer');
      if (value !== null) {
        context.commit('set', JSON.parse(value));
        context.dispatch('user/setFormFromCustomerData', null, { root: true });
        context.dispatch('shipping/setFormFromCustomerData', null, { root: true });
      }
    },
    setGuestCustomer(context, data) {
      return new Promise((resolve) => {
        const d = data;
        d.isGuest = true;
        context.commit('set', d);
        window.sessionStorage.setItem('customer', JSON.stringify(data));
        resolve();
      });
    },
    clear(context) {
      context.commit('clear');
    },
  },
};
