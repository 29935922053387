let loadedModule; //eslint-disable-line
const nm = () => import('./nm.js');
const fvv = () => import('./fvv.js');
const gh = () => import('./gh.js');
const max = () => import('./max.js');
const mulures = () => import('./mulures.js');
const khb = () => import('./khb.js');
const kmb = () => import('./kmb.js');
const noCustom = () => import('./noCustom.js');

if (['nm', 'devnm'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = nm;
} else if (['fvv', 'devfvv'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = fvv;
} else if (['gh', 'devgh'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = gh;
} else if (['max', 'devmax'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = max;
} else if (['mulures', 'devmulures'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = mulures;
} else if (['khb', 'devkhb'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = khb;
} else if (['kmb', 'devkmb'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  loadedModule = kmb;
} else {
  loadedModule = noCustom;
}

export default loadedModule;
