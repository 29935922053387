import { createStore } from 'vuex';
import { updateSiteName } from '../lib/pageTitle';

import banners from './banners';
import cart from './cart';
import cookieDisclaimer from './cookieDisclaimer';
import countries from './countries';
import customer from './customer';
import directDebit from './directDebit';
import footer from './footer';
import globalMessage from './globalMessage';
import guidedTours from './guidedTours';
import history from './history';
import meta from './meta';
import payments from './payments';
import products from './products';
import request from '../request';
import salutations from './salutations';
import shipping from './shipping';
import staticContent from './staticContent';
import tickets from './tickets';
import user from './user';
import voucherCodes from './voucherCodes';
import vouchers from './vouchers';
import waitingForServer from './waitingForServer';

const apiURL = import.meta.env.VITE_API_URL; //eslint-disable-line

function parseLanguages(l) {
  const parsedLanguages = [];
  l.forEach((language) => {
    parsedLanguages.push(language.name.substring(0, 2));
  });
  return parsedLanguages;
}
const languages = parseLanguages(JSON.parse(import.meta.env.VITE_LANGUAGES));

function parseLocales(l) {
  const parsedLocales = [];
  l.forEach((language) => {
    parsedLocales.push(language.name);
  });
  return parsedLocales;
}

const locales = parseLocales(JSON.parse(import.meta.env.VITE_LANGUAGES));

function parseLanguagesFull(l) {
  const parsedLanguages = [];
  l.forEach((language) => {
    parsedLanguages.push(language.fullname);
  });
  return parsedLanguages;
}
const languagesFull = parseLanguagesFull(JSON.parse(import.meta.env.VITE_LANGUAGES)); //eslint-disable-line

const RootState = {
  saveSuccess: false,
  saveError: false,
  waiting: false,
  app: {
    // Main APP Settings Should Go Here
    endpoints: {
      PRODUCT: 'product',
      LOGIN: 'auth/login',
      MERCHANDISE: 'merchandise/select',
      MERCHANDISE_SINGLE: 'merchandise',
      MERCHANDISE_CATEGORY_LIST: 'merchandise/attr',
      GUIDED_TOURS: 'tour/select/event',
      GUIDED_TOURS_OVERVIEW: 'tour/overview', // ?date=2023-07-26&lookahead=4 default today and 4
      GUIDED_TOURS_SINGLE: 'tour', // ?tour=id
      GUIDED_TOURS_SOLDOUT: 'tour/soldout/tour/', // /tourid
      GUIDED_TOURS_SINGLE_CALENDAR: 'tour/calendar/tour/', // /id/range/
      GUIDED_TOURS_SINGLE_LIMITS: 'tour/lifetime/tour/', // id
      GUIDED_TOURS_CATEGORY_LIST: 'tour/attr',
      GUIDED_TOURS_SOLDOUT_TIMESLOTS: 'tour/soldoutTimeslots/tour/', // 5753/date/2023-08-24
      TICKETS_SINGLE_TICKET_TYPES: 'timepermit/select/event/',
      EVENT_LIST: 'event/select',
      TICKET_LIST: 'ticket/collect/event/', // 1950/date/2018-06-30',
      EVENT_CALENDAR: 'ticket/calendar/event/', // 1950/range/20180601;20180831/'
      EVENT_LIFETIME: 'ticket/lifetime', // ?event=eventId
      USER_REGISTER_FORM_DATA: 'customer/attr',
      REGISTER: 'customer/register',
      REGISTER_GUEST: 'auth/guest',
      REGISTER_VALIDATE: 'customer/validate',
      PASSWORD_RESET: 'customer/pwdreset',
      PASSWORD_CONFIRM: 'customer/pwdconfirm',
      PASSWORD_CHANGE: 'customer/pwdchange',
      EMAIL_CHANGE: 'customer/emailchange',
      CUSTOMER_DATA: 'customer',
      CUSTOMER_UPDATE: 'customer/update',
      CUSTOMER_VALIDATE: 'customer/validate',
      VOUCHER_LIST: 'voucher/select/event/',
      VOUCHER_SINGLE: 'voucher/selectOne/price/',
      VOUCHER: 'voucher',

      ORDER: 'order',
      PAYMENT_TYPES: `checkout/payments?shop=${import.meta.env.VITE_SHOP_ID}`,
      PAYMENT_INIT: 'checkout/initPayment',
      PAYMENT_EXEC: 'checkout/execPayment',
      PAYMENT_CANCEL: 'checkout/cancel',
      STATIC_CONTENT: `shopinfo?shop=${import.meta.env.VITE_SHOP_ID}`,
      REMOTE_CONFIG: `shopinfo/config?shop=${import.meta.env.VITE_SHOP_ID}`,
      SHIPPING_COSTS: 'checkout/shipping',
    },
    API_URL: apiURL,
    paypalExpress: JSON.parse(import.meta.env.VITE_PAYPAL_EXPRESS), //eslint-disable-line
    applePay: JSON.parse(import.meta.env.VITE_APPLE_PAY), //eslint-disable-line
    googlePay: JSON.parse(import.meta.env.VITE_GOOGLE_PAY), //eslint-disable-line
    hasUserRegistration: eval(import.meta.env.VITE_HAS_USER_REGISTRATION), // eslint-disable-line
    /*   siteName: import.meta.env.VITE_SITE_NAME,
    siteNameTranslations: import.meta.env.VITE_SITE_NAME_TRANSLATIONS,
    */
    // public directory normally to get the path for images
    API_PUBLIC_URL: import.meta.env.VITE_API_PUBLIC_URL,
    /*
    APIVersion: import.meta.env.VITE_API_VERSION,
    museumMainUrl: import.meta.env.VITE_MUSEUM_MAIN_URL,
    museumPerLanguageUrls: JSON.parse(import.meta.env.VITE_MUSEUM_PER_LANGUAGE_URLS), //eslint-disable-line
    hasMerchandise: eval(import.meta.env.VITE_HAS_MERCHANDISE), // eslint-disable-line
    hasBillingFormOnCheckout: eval(import.meta.env.VITE_HAS_BILLING_FORM_ON_CHECKOUT), //eslint-disable-line
    hasVoucherCode: eval(import.meta.env.VITE_HAS_VOUCHER_CODE), // eslint-disable-line
    hasVouchers: eval(import.meta.env.VITE_HAS_VOUCHERS), //eslint-disable-line
    */
    hasCheckoutDocumentNumber: eval(import.meta.env.VITE_CHECKOUT_DOCUMENT_NUMBER), //eslint-disable-line
    /*
    hasTickets: eval(import.meta.env.VITE_HAS_TICKETS), //eslint-disable-line
    hasGuidedTours: eval(import.meta.env.VITE_HAS_GUIDED_TOURS), //eslint-disable-line
    */
    hasGuestRegistration: eval(import.meta.env.VITE_HAS_GUEST_REGISTRATION), // eslint-disable-line
    /*
    requireEmailConfimationOnRegistration: eval(import.meta.env.VITE_REQUIRE_EMAIL_CONFIRMATION_ON_REGISTRATION), // eslint-disable-line
    showHeader: eval(import.meta.env.VITE_SHOW_HEADER), // eslint-disable-line
    showFooter: eval(import.meta.env.VITE_SHOW_FOOTER), // eslint-disable-line
    showBreadcrumbs: eval(import.meta.env.VITE_SHOW_BREADCRUMBS), // eslint-disable-line
    shortBreadcrumbs: eval(import.meta.env.VITE_SHORT_BREADCRUMBS), // eslint-disable-line
    showProgressBar: eval(import.meta.env.VITE_SHOW_PROGRESS_BAR), // eslint-disable-line
    logoLinksToMainSite: eval(import.meta.env.VITE_LOGO_LINKS_TO_MAIN_SITE), //eslint-disable-line
    defaultUserCountryKey: import.meta.env.VITE_DEFAULT_USER_COUNTRY_KEY,
    languages,
    languagesFull,
    preferedLanguage: import.meta.env.VITE_PREFERED_LANGUAGE,
    */
    currencySymbol: import.meta.env.VITE_CURRENCY,
    /*
    shopId: import.meta.env.VITE_SHOP_ID,
    */
    confirmPaymentOnCheckout: eval(import.meta.env.VITE_CONFIRM_PAYMENT_ON_CHECKOUT), //eslint-disable-line
    /*
    customTermsLinks: JSON.parse(import.meta.env.VITE_CUSTOM_TERMS_LINKS), // eslint-disable-line
    guidedTourDefaultLanguage: import.meta.env.VITE_GUIDED_TOUR_DEFAULT_LANGUAGE,
    paymentTypes: eval(import.meta.env.VITE_PAYMENT_TYPES), //eslint-disable-line
    */
    ticketURL: import.meta.env.VITE_TICKET_URL,
    /*
    navTranslations: JSON.parse(import.meta.env.VITE_NAV_TRANSLATIONS), //eslint-disable-line
    showCookieDisclaimer: import.meta.env.VITE_COOKIE_DISCLAIMER,
    */
    partnerLogos: JSON.parse(import.meta.env.VITE_PARTNER_LOGOS), //eslint-disable-line
  },
  language: languages[0],
  locale: locales[0],
  locales,
  showAllValidationErrors: false,
};

const mutations = {
  setLanguage(state, language) {
    Object.assign(state, { language });
    const locale = state.locales.find((l) => l.includes(language));
    Object.assign(state, { locale });
    document.getElementsByTagName('html')[0].setAttribute('lang', language);
  },
  clearState(gState) {
    const { language } = gState;
    Object.assign(gState, {});
    window.location = `/${language}`;
  },
  setShowAllValidationErrors(state, showAllValidationErrors) {
    Object.assign(state, { showAllValidationErrors });
  },
  setRemoteConfig(state, payload) {
    state.app.siteName = payload.site_name;
    state.app.museumMainUrl = payload.extern_museum_main_url;
    state.app.logoLinksToMainSite = payload.logo_links_to_main_site === '1';
    state.app.showCookieDisclaimer = payload.cookie_disclaimer === '1';
    state.app.customTermsLinks = payload.custom_terms_links;
    if (payload.custom_terms_links === '0') {
      state.app.customTermsLinks = false;
    }
    state.app.defaultUserCountryKey = payload.default_user_country_key;
    state.app.guidedTourDefaultLanguage = payload.guided_tour_default_language;
    state.app.hasGuidedTours = payload.has_guided_tours === '1';
    state.app.hasMerchandise = payload.has_merchandise === '1';
    state.app.hasMemCard = payload.has_memcard === '1';
    state.app.hasTickets = payload.has_tickets === '1';
    state.app.hasVouchers = payload.has_vouchers === '1';
    state.app.hasVoucherCode = payload.has_voucher_code === '1';
    // state.app.hasCheckoutDocumentNumber = payload.has_checkout_document_number === '1';
    state.app.languages = parseLanguages(payload.languages);
    state.app.languagesFull = parseLanguagesFull(payload.languages);

    state.locales = parseLocales(payload.languages);
    state.locale = state.locales[0];

    state.app.museumMainUrl = payload.museum_main_url;
    let museumPerLanguageUrls = {};
    if (typeof payload.museum_per_language_urls !== 'undefined') {
      museumPerLanguageUrls = payload.museum_per_language_urls;
    }
    state.app.museumPerLanguageUrls = museumPerLanguageUrls;
    const navTranslations = {};
    Object.keys(payload.nav_translations).forEach((k) => {
      navTranslations[k.toUpperCase()] = payload.nav_translations[k];
    });
    state.app.navTranslations = navTranslations;
    state.app.preferedLanguage = payload.prefered_language;
    // state.app. = payload.VUE_APP_SHOP_REF; //NOT NEEDED show has to know this beforehand
    state.app.homeView = payload.home_view;
    state.app.showProgressBar = payload.show_progress_bar === '1';
    state.app.siteNameTranslations = payload.site_name_translations;
  },
};

const actions = {
  setLanguage(context, payload) {
    context.commit('setLanguage', payload);
    context.dispatch('cookieDisclaimer/setVisible');
  },
  showAllValidationErrors(context, showAllValidationErrors) {
    context.commit('setShowAllValidationErrors', showAllValidationErrors);
  },
  getRemoteConfig(context) {
    return new Promise((resolve) => {
      const { app } = context.rootState;
      request.make(
        {
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.REMOTE_CONFIG}`,
        },
        (err, res) => {
          if (res.status === 200) {
            if (res.body.data) {
              context.commit('setRemoteConfig', res.body.data);
              updateSiteName(context.state.app.siteName);
            }
          }
          resolve();
        }
      );
    });
  },
};

export default createStore({
  strict: import.meta.env.VITE_NODE_ENV !== 'production',
  state: RootState,
  actions,
  mutations,
  modules: {
    banners,
    cart,
    cookieDisclaimer,
    countries,
    customer,
    directDebit,
    footer,
    globalMessage,
    guidedTours,
    history,
    meta,
    payments,
    products,
    request,
    salutations,
    shipping,
    staticContent,
    tickets,
    user,
    voucherCodes,
    vouchers,
    waitingForServer,
  },
});
