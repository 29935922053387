import moment from 'moment-mini';
import request from '../request';

const baseState = {
  loading: false,
  valid: false,
  data: {
    amount: null,
    barcode: '',
    id: '',
    name: '',
    used_amount: null,
    valid_from: '',
    valid_to: '',
  },
  errors: {
    code: null,
  },
  form: {
    code: '',
  },
};

function isVoucherValid(v) {
  const validFrom = moment(v.valid_from);
  const validTo = moment(v.valid_to);
  const now = moment().utcOffset('+1');
  if (now.isBefore(validFrom)) {
    return 'VOUCHER_NOW_IS_BEFORE_VALID_FROM';
  }
  if (now.isAfter(validTo)) {
    return 'VOUCHER_NOW_IS_AFTER_VALID_TO';
  }
  if (parseInt(v.used_amount, 10) >= parseInt(v.amount, 10)) {
    return 'VOUCHER_NO_AMOUNT_LEFT';
  }
  return true;
}

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(baseState)),
  mutations: {
    setCode(state, code) {
      state.form = { code };
    },
    trimCode(state) {
      state.form = { code: state.form.code.trim() };
    },
    clearForm(state) {
      state.form = JSON.parse(JSON.stringify(baseState.form));
      state.errors = JSON.parse(JSON.stringify(baseState.errors));
    },
    setError(state, errors) {
      Object.assign(state, { errors });
    },
    setValid(state, valid) {
      Object.assign(state.valid, valid);
    },
    setLoading(state, loading) {
      Object.assign(state, { loading });
    },
    setData(state, data) {
      Object.assign(data, { amount: parseInt(data.amount, 10) });
      Object.assign(data, { used_amount: parseInt(data.used_amount, 10) });
      const availableAmount = parseInt(data.amount, 10) - parseInt(data.used_amount, 10);
      Object.assign(data, { available_amount: availableAmount });
      Object.assign(state, { data });
    },
  },
  actions: {
    setCode(context, code) {
      context.commit('setCode', code);
    },
    clearForm(context) {
      context.commit('clearForm');
    },
    add(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true);
        context.commit('trimCode');
        const { app } = context.rootState;
        request.make(
          {
            type: 'GET',
            url: `${app.API_URL}${context.rootState.language}/${app.endpoints.VOUCHER}?voucher=${encodeURIComponent(context.state.form.code)}`,
          },
          (err, res) => {
            context.commit('setLoading', false);
            if (err !== null) {
              if (res.status === 422 && typeof res.body.error !== 'undefined') {
                context.commit('setError', { code: res.body.error });
                context.commit('setValid', false);
                reject();
              } else {
                context.commit('setError', { code: 'VOUCHER_UNKNOWN_ERROR' });
                context.commit('setValid', false);
                reject();
              }
            } else {
              context.commit('setError', { code: '' });
              const validation = isVoucherValid(res.body.data);
              if (validation !== true) {
                context.commit('setValid', false);
                context.commit('setError', { code: validation });
                reject();
              }
              context.commit('setValid', true);
              context.commit('setData', res.body.data);
              resolve();
            }
          }
        );
      });
    },
    addToCart(context) {
      const item = {
        type: 'voucherCode',
        barcode: context.state.data.barcode,
        name: context.state.data.name,
        totalCount: 1,
        totalPrice: context.state.data.available_amount * -1,
      };
      context.dispatch('cart/add', item, { root: true });
    },
  },
};
