import request from '../request';
import analytics from '../analytics';

function makeUS(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const perSessionUS = makeUS(12);

function prepareCart(cart) {
  const newCart = [];
  cart.forEach((item) => {
    let newItem = {};
    if (item.type !== 'guidedTour') {
      Object.assign(newItem, item);
    }
    if (item.type === 'guidedTour') {
      newItem = {};
      newItem.type = 'guidedTour';
      newItem.answers = item.answers;
      newItem.datetime = `${item.date} ${item.time}:00`;
      newItem.tourId = item.tourId;

      newItem.prices = [];

      item.tours.forEach((t) => {
        const price = {
          id: t.id,
          count: t.count,
        };
        newItem.prices.push(price);
      });
      newItem.tickets = [];
      item.tickets.forEach((ticket) => {
        const newTicket = {
          id: ticket.id,
          count: ticket.count,
        };
        if (typeof ticket.subtickets !== 'undefined') {
          newTicket.subtickets = [];
          ticket.subtickets.forEach((subticket) => {
            newTicket.subtickets.push({
              id: subticket.id,
              count: subticket.count,
            });
          });
        }
        newItem.tickets.push(newTicket);
      });
      newCart.push(newItem);
    } else if (item.type === 'ticket') {
      const t = {
        type: 'ticket',
        count: newItem.totalCount,
        id: newItem.id,
        datetime: newItem.datetime,
      };
      if (typeof newItem.subtickets !== 'undefined') {
        t.subtickets = [];
        newItem.subtickets.forEach((st) => {
          t.subtickets.push({
            id: parseInt(st.id, 10),
            count: st.count,
          });
        });
      }
      newCart.push(t);
    } else if (item.type === 'voucherCode') {
      const v = {
        type: 'voucherCode',
        barcode: newItem.barcode,
      };
      newCart.push(v);
    } else {
      newCart.push(newItem);
    }
  });
  return newCart;
}

function calculateTotalTax(cart) {
  let totalTax = 0;
  cart.forEach((item) => {
    const taxPercentage = parseInt(item.tax, 10) / 100 + 1;
    const priceWithTax = item.totalPrice / 100;
    const totalWithoutTax = priceWithTax / taxPercentage;
    const taxVal = priceWithTax - totalWithoutTax;
    totalTax += taxVal;
  });
  return (Math.round(totalTax * 100) / 100).toFixed(2);
}

const localTypes = eval(import.meta.env.VITE_PAYMENT_TYPES); //eslint-disable-line

let types = [];

if (localTypes) {
  if (localTypes[0]) {
    if (localTypes[0].id) {
      types = localTypes;
    }
  }
}
export default {
  namespaced: true,
  state: {
    partner: null,
    loaded: false,
    loading: false,
    canceling: false,
    acceptsTerms: false,
    hasTickets: false,
    gone: false,
    data: {
      id: null,
      types,
    },
    error: '',
  },
  mutations: {
    set(state, payments) {
      const newPaymentTypes = [];
      const localP = JSON.parse(JSON.stringify(localTypes));
      payments.forEach((p) => {
        const newPayment = { id: p.payartId };
        newPayment.name = {};
        Object.keys(p.i18n).forEach((k) => {
          newPayment.name[k] = p.i18n[k];
        });
        newPaymentTypes.push(newPayment);
      });
      localP.forEach((lp) => {
        if (!payments.find((p) => lp.id === p.payartId)) {
          newPaymentTypes.push(lp);
        }
      });
      state.data.types = newPaymentTypes;
    },
    setLoaded(state, v) {
      state.loaded = v;
    },
    setLoading(state, v) {
      state.loading = v;
    },
    setId(state, id) {
      Object.assign(state.data, { id });
    },
    setError(state, error) {
      state.error = error;
    },
    setGone(state, v) {
      state.gone = v;
    },
    setAcceptsTerms(state, acceptsTerms) {
      Object.assign(state, { acceptsTerms });
    },
    setCanceling(state, canceling) {
      Object.assign(state, { canceling });
    },
    setHasTickets(state, hasTickets) {
      state.hasTickets = hasTickets;
    },
    setPartner(state, v) {
      state.partner = v;
    },
  },
  actions: {
    get(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        context.commit('setLoaded', false);
        context.commit('setLoading', true);
        let url = `${app.API_URL}${context.rootState.language}/${app.endpoints.PAYMENT_TYPES}`;
        let jwtURLString = '';
        let argumentSeparator = '?';
        if (url.includes('?')) {
          argumentSeparator = '&';
        }
        if (context.rootState.user.data.jwt !== '') {
          jwtURLString = `${argumentSeparator}_=${context.rootState.user.data.jwt}`;
        }
        url = `${app.API_URL}${context.rootState.language}/${app.endpoints.PAYMENT_TYPES}${jwtURLString}`;
        const type = 'GET';
        request.make(
          {
            url,
            type,
          },
          (err, res) => {
            if (!err) {
              context.commit('set', res.body.data);
              context.commit('setLoaded', true);
            } else {
              context.commit('setLoaded', false);
            }
            context.commit('setLoading', false);
            resolve();
          }
        );
      });
    },
    setError(context, error) {
      context.commit('setError', error);
    },
    initPayment(context, { typeId, typeName }) {
      let externalDocumentNumber = '';
      if (context.rootState.cart.externalDocumentNumber) {
        externalDocumentNumber = context.rootState.cart.externalDocumentNumber;
      } else {
        externalDocumentNumber = undefined;
      }

      if (context.rootState.cart.externalDocumentNumber) {
        externalDocumentNumber = context.rootState.cart.externalDocumentNumber;
      } else {
        externalDocumentNumber = undefined;
      }

      const { app } = context.rootState;
      const type = 'POST';
      let url = `${app.API_URL}${context.rootState.language}/${app.endpoints.PAYMENT_INIT}`;
      if (context.rootState.user.data.jwt !== '') {
        url += `?_=${context.rootState.user.data.jwt}`;
      }
      const items = prepareCart(context.rootState.cart.form);
      let aux;
      if ([74, 164].includes(typeId)) {
        aux = {
          Accountholder: context.rootState.directDebit.form.accountHolder,
          IBAN: context.rootState.directDebit.form.IBAN,
        };
      } else if (typeName === 'applePay') {
        aux = {
          wallets: ['APPLEPAY'],
        };
      } else if (typeName === 'googlePay') {
        aux = {
          wallets: ['GOOGLEPAY'],
        };
      }
      if (context.state.partner !== null) {
        if (typeof aux === 'undefined') {
          aux = {};
        }
        aux.partner = context.state.partner;
      }
      const data = {
        payart: parseInt(typeId, 10),
        aux,
        cart: {
          ver: 1.0,
          items,
          external_document_number: externalDocumentNumber,
        },
      };
      const failedReturnURL = window.vue.$router.resolve({
        name: 'checkoutFailed',
        params: { lang: context.rootState.language },
      }).href;

      let acceptedReturnURL = window.vue.$router.resolve({
        name: 'checkoutAccepted',
        params: { lang: context.rootState.language },
      }).href;
      if (app.confirmPaymentOnCheckout) {
        acceptedReturnURL = window.vue.$router.resolve({
          name: 'checkoutSuccess',
          params: { lang: context.rootState.language },
        }).href;
      }
      data.url = {
        continue: `${window.location.origin}${acceptedReturnURL}`,
        cancel: `${window.location.origin}${failedReturnURL}`,
      };
      context.commit('setLoading', true);
      let paymentName = null;
      const payment = context.state.data.types.find(
        (p) => parseInt(p.id, 10) === parseInt(typeId, 10)
      );
      if (typeof payment !== 'undefined') {
        paymentName = payment.name;
      }
      analytics.proceedToCheckout(context.rootState.cart.form, paymentName, 1);
      request.make(
        {
          type,
          url,
          data,
        },
        (err, res) => {
          context.commit('setLoading', false);
          if (res.status === 200) {
            context.dispatch('cart/setError', '', { root: true });
            window.location = res.body.data;
          }
          if (res.status === 422) {
            if (typeof res.body.error !== 'undefined') {
              context.dispatch('cart/setError', res.body.error, { root: true });
            }
          }
        }
      );
    },
    execPayment(context) {
      return new Promise((resolve) => {
        context.dispatch('user/setSavedToken', null, { root: true }).then(() => {
          context.commit('setError', '');
          const { app } = context.rootState;
          const type = 'POST';
          let url = `${app.API_URL}${context.rootState.language}/${app.endpoints.PAYMENT_EXEC}`;
          let isFirstParam = true;
          if (context.rootState.user.data.jwt !== '') {
            url += `?_=${context.rootState.user.data.jwt}`;
            isFirstParam = false;
          }
          if (isFirstParam) {
            url += '?';
          } else {
            url += '&';
          }
          url += `us=${makeUS(12)}`;
          url += `&sessionUs=${perSessionUS}`;
          url += `&srcURL=${encodeURIComponent(document.location.pathname)}`;

          const data = {
            cartId: context.rootState.cart.cartId,
          };
          context.commit('setLoading', true);
          analytics.makePurchase(
            context.rootState.cart.form,
            context.rootState.cart.cartId,
            context.rootGetters['cart/total'],
            calculateTotalTax(context.rootState.cart.form)
          );
          request.make(
            {
              type,
              url,
              data,
            },
            (err, res) => {
              context.commit('setLoading', false);
              if (res.status === 422) {
                const error = JSON.parse(res.text);
                context.commit('setError', error.error);
                resolve();
              } else if (res.status === 400) {
                const error = JSON.parse(res.text);
                context.commit('setError', error.message);
                resolve();
              } else if (res.status === 410) {
                context.commit('setGone', true);
                resolve();
              } else if (res.status === 200) {
                if (typeof res.body.data !== 'undefined') {
                  context.commit('setId', res.body.data.booking_id);
                  context.commit('setHasTickets', res.body.data.has_tickets);
                }
                resolve();
              }
            }
          );
        });
      });
    },
    setGone(context, v) {
      context.commit('setGone', v);
    },
    setAcceptsTerms(context, acceptsTerms) {
      context.commit('setAcceptsTerms', acceptsTerms);
    },
    setPartner(context, v) {
      if (v !== null) {
        context.commit('setPartner', v);
        sessionStorage.setItem('partner', v);
      } else {
        const savedPartner = sessionStorage.getItem('partner');
        if (savedPartner !== null) {
          context.commit('setPartner', savedPartner);
        }
      }
    },
    cancelPayment(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        context.commit('setCanceling', true);
        request.make(
          {
            type: 'POST',
            url: `${app.API_URL}${context.rootState.language}/${app.endpoints.PAYMENT_CANCEL}?noRedirect=1&cartId=${context.rootState.cart.cartId}`,
          },
          (err, res) => {
            context.commit('setCanceling', false);
            if (res.status === 200) {
              if (res.body.data === true) {
                context.dispatch('cart/setCartId', null, { root: true });
                resolve();
              }
            } else if (res.status === 410) {
              context.commit('setGone', true);
            }
          }
        );
      });
    },
    setHasTickets(context, v) {
      context.commit('setHasTickets', v);
    },
    setBookingId(context, v) {
      context.commit('setId', v);
    },
  },
};
