<template>
  <div v-bind:class="{ scrolled: scrolled }">
    <MenuBlock></MenuBlock>
  </div>
</template>

<script>
import Menu from '../Menu/Menu.vue';

export default {
  name: 'HeaderComponent',
  data() {
    return {
      scrolled: false,
    };
  },
  components: {
    MenuBlock: Menu,
  },
  methods: {
    onScroll() {
      if (window.scrollY > 100) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
  },
  unmounted() {
    document.removeEventListener('scroll', this.onScroll);
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped></style>
