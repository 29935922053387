<template>
  <li class="dropdown" v-if="tag === 'li'">
    <a class="dropdown-toggle" v-on:click="toggle()">
      {{ text }}
      <span class="caret"></span>
    </a>
    <ul class="dropdown-menu" v-if="!disabled">
      <slot></slot>
    </ul>
  </li>
  <div class="dropdown" v-else>
    <a class="dropdown-toggle" v-on:click="toggle()">{{ text }}</a>
    <ul class="dropdown-menu" v-if="!disabled" v-on:click="toggle()">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DropdownComponent',
  data() {
    return {
      disabled: true,
    };
  },
  props: {
    text: { required: true },
    tag: {
      default: 'li',
    },
  },
  methods: {
    toggle() {
      this.disabled = !this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.dropdown-menu {
  display: block !important;
}

.dropdown-toggle {
  white-space: nowrap;
}
</style>
