import request from '../request';
import moment from 'moment-mini';

function isNumeric(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

export default {
  namespaced: true,
  state: {
    hasLoadedNextFewDays: false,
    nextFewDays: {
      today: false,
      tomorrow: false,
      inTwoDays: false,
      inThreeDays: false,
      inFourDays: false,
    },
    isLoadingList: false,
    isLoadedList: false,
    isLoadingSingle: false,
    isLoadingSingleCalendarLimits: false,
    isLoadedSingleCalendarLimits: false,
    isLoadingSingleCalendar: false,
    isLoadedSingleCalendar: false,
    isNotFoundSingle: false,
    isNotFoundSingleCalendar: false,
    isLoadedSoldOutDates: false,
    isLoadingListPerDay: false,
    selectedDay: null,
    isToursPerDateLoaded: false,
    selectedLanguages: {},
    categories: [],
    hasMultipleVenues: false,
    venues: {},
    data: {
      soldOutDates: {},
      list: [],
      single: {},
      singleCalendar: {},
      singleCalendarLimits: {},
      toursPerDate: {},
    },
    form: {
      date: undefined,
      time: undefined,
      timeslotId: undefined,
      tickets: {},
      tours: {},
      ticketCount: 0,
      tourCount: 0,
      totalPrice: 0,
      answers: {},
    },
  },
  mutations: {
    setHasLoadedNextFewDays(state, p) {
      state.hasLoadedNextFewDays = p;
    },
    setNextFewDays(state) {
      const toursPerDate = state.data.toursPerDate;
      const today = `${moment().format('YYYY-MM-DD')}`;
      const tomorrow = `${moment().add(1, 'day').format('YYYY-MM-DD')}`;
      const inTwoDays = `${moment().add(2, 'day').format('YYYY-MM-DD')}`;
      const inThreeDays = `${moment().add(3, 'day').format('YYYY-MM-DD')}`;
      const inFourDays = `${moment().add(3, 'day').format('YYYY-MM-DD')}`;

      if (typeof toursPerDate[today] !== 'undefined') {
        if (toursPerDate[today].length > 0) {
          state.nextFewDays.today = true;
        }
      }
      if (typeof toursPerDate[tomorrow] !== 'undefined') {
        if (toursPerDate[tomorrow].length > 0) {
          state.nextFewDays.tomorrow = true;
        }
      }
      if (typeof toursPerDate[inTwoDays] !== 'undefined') {
        if (toursPerDate[inTwoDays].length > 0) {
          state.nextFewDays.inTwoDays = true;
        }
      }
      if (typeof toursPerDate[inThreeDays] !== 'undefined') {
        if (toursPerDate[inThreeDays].length > 0) {
          state.nextFewDays.inThreeDays = true;
        }
      }
      if (typeof toursPerDate[inFourDays] !== 'undefined') {
        if (toursPerDate[inFourDays].length > 0) {
          state.nextFewDays.inFourDays = true;
        }
      }
    },
    clearEmptyCategories(state) {
      let categoryIds = [];
      state.categories.forEach((c) => {
        if (!categoryIds.includes(c.id)) {
          categoryIds.push(c.id);
        }
        state.data.list.forEach((g) => {
          if (typeof g.custom_groups !== 'undefined') {
            g.custom_groups.forEach((cg) => {
              categoryIds = categoryIds.filter((id) => !(cg.id === id));
            });
          }
        });
      });
      const newCategories = state.categories.filter((c) => !categoryIds.includes(c.id));
      state.categories = newCategories;
    },
    setList(state, { payload, language }) {
      const languages = {};
      let alternateLanguage = null;
      let hasSelectedLanguage = false;
      payload.forEach((tour) => {
        if (typeof languages[tour.langIdentifier.toLowerCase()] === 'undefined') {
          let selected = false;
          if (tour.langIdentifier.toLowerCase() === language) {
            selected = true;
            hasSelectedLanguage = true;
          }
          if (
            tour.langIdentifier.toLowerCase() ===
            window.vue.$store.state.app.guidedTourDefaultLanguage
          ) {
            alternateLanguage = tour.langIdentifier.toLowerCase();
          }
          languages[tour.langIdentifier.toLowerCase()] = { name: tour.langName, selected };
        }
        if (typeof tour.custom_groups === 'undefined') {
          tour.custom_groups = []; // eslint-disable-line
        }
      });
      if (!hasSelectedLanguage && alternateLanguage !== null) {
        languages[alternateLanguage].selected = true;
      }
      state.selectedLanguages = languages;
      state.data.list = payload;
    },
    isLoadingListPerday(state, v) {
      state.isLoadingListPerDay = v;
    },
    setAnswer(state, answer) {
      if (answer.type === 'radio' || answer.type === 'select') {
        state.form.answers[answer.questionId] = {
          qId: answer.questionId,
          type: answer.type,
          value: answer.answerId,
        };
      } else if (answer.type === 'checkbox') {
        let newArray = [];
        if (typeof state.form.answers[answer.questionId] !== 'undefined') {
          if (state.form.answers[answer.questionId].value.includes(answer.answerId)) {
            newArray = state.form.answers[answer.questionId].value.filter(
              (e) => e !== answer.answerId
            );
          } else {
            state.form.answers[answer.questionId].value.push(answer.answerId);
            newArray = state.form.answers[answer.questionId].value;
          }
          state.form.answers[answer.questionId].value = newArray;
        } else {
          state.form.answers[answer.questionId] = {
            qId: answer.questionId,
            type: answer.type,
            value: [answer.answerId],
          };
        }
      } else if (answer.type === 'text' || answer.type === 'textarea' || answer.type === 'number') {
        state.form.answers[answer.questionId] = {
          qId: answer.questionId,
          type: answer.type,
          value: answer.value,
        };
      }
    },
    clearAnswers(state) {
      state.form.answers = {};
    },
    setToursPerDate(state, payload) {
      state.data.toursPerDate = {};
      const result = state.data.toursPerDate;
      Object.keys(payload).forEach((d) => {
        result[d] = [];
        payload[d].forEach((t) => {
          result[d].push(t.id);
        });
      });
      state.data.toursPerDate = result;
      state.isToursPerDateLoaded = true;
    },
    setSelectedDay(state, payload) {
      state.selectedDay = payload;
    },
    setSingle(state, payload) {
      state.data.single = payload;
    },
    setSoldOut(state, payload) {
      state.data.soldOutDates = payload;
    },
    setLoadedSoldOut(state, v) {
      state.isLoadedSoldOutDates = v;
    },
    setPriceListOrder(state) {
      let priceList = {};
      if (typeof state.data.single.priceList !== 'undefined') {
        priceList = state.data.single.priceList;
      }
      if (typeof state.form.time !== 'undefined') {
        if (typeof state.data.single.schedule !== 'undefined') {
          if (typeof state.data.single.schedule[state.form.time] !== 'undefined') {
            if (typeof state.data.single.schedule[state.form.time].priceList !== 'undefined') {
              priceList = state.data.single.schedule[state.form.time].priceList;
            }
          }
        }
      }
      const priceListOrder = Object.keys(priceList).sort((a, b) => {
        const r = parseInt(priceList[a].sequence_no, 10) > parseInt(priceList[b].sequence_no, 10);
        if (r) {
          return 1;
        }
        return -1;
      });
      state.data.single.priceListOrder = priceListOrder;
    },
    setTickets(state, payload) {
      if (typeof payload.tickets === 'undefined') {
        state.data.single.tickets = {};
      } else {
        const ticketOrder = Object.keys(payload.tickets).sort(
          (aId, bId) =>
            parseInt(payload.tickets[aId].sequence_no, 0) -
            parseInt(payload.tickets[bId].sequence_no, 0)
        );
        state.data.single.tickets = payload.tickets;
        state.data.single.ticketOrder = ticketOrder;
      }
    },
    setSubTicketCount(state, { ticketId, subTicketId, count }) {
      Object.assign(state.form.tickets[ticketId].subTickets[subTicketId], { count });
    },
    clearSingleCalendar(state) {
      state.data.singleCalendar = {};
      state.data.single = {};
      state.data.singleCalendarLimits = {};
      state.isLoadedSingleCalendar = false;
      state.isLoadingSingleCalendar = false;
    },
    setLoadingList(state, isLoadingList) {
      Object.assign(state, { isLoadingList });
    },
    setLoadedList(state, isLoadedList) {
      Object.assign(state, { isLoadedList });
    },
    setLoadingSingle(state, isLoadingSingle) {
      Object.assign(state, { isLoadingSingle });
    },
    setLoadingSingleCalendarLimits(state, isLoadingSingleCalendarLimits) {
      Object.assign(state, { isLoadingSingleCalendarLimits });
    },
    setNotFoundSingle(state, isNotFoundSingle) {
      Object.assign(state, { isNotFoundSingle });
    },
    setSingleCalendar(state, payload) {
      state.data.singleCalendar = payload;
    },
    setSingleCalendarLimits(state, payload) {
      state.data.singleCalendarLimits = payload;
    },
    setLoadingSingleCalendar(state, isLoadingSingleCalendar) {
      Object.assign(state, { isLoadingSingleCalendar });
    },
    setNotFoundSingleCalendar(state, isNotFoundSingleCalendar) {
      Object.assign(state, { isNotFoundSingleCalendar });
    },

    setLoadedSingleCalendar(state, isLoadedSingleCalendar) {
      Object.assign(state, { isLoadedSingleCalendar });
    },
    setLoadedSingleCalendarLimits(state, isLoadedSingleCalendarLimits) {
      Object.assign(state, { isLoadedSingleCalendarLimits });
    },
    setTicketOptions(state, payload) {
      state.form.tickets = {};
      Object.keys(payload).forEach((key) => {
        const ticket = payload[key];
        const ticketId = ticket.id;
        let vatValue = parseFloat(ticket.vat_value);
        if (Number.isNaN(vatValue)) {
          vatValue = 0;
        }
        let subTickets;
        if (typeof ticket.subtickets !== 'undefined') {
          subTickets = {};
          ticket.subtickets.forEach((subticket) => {
            subTickets[subticket.id] = {
              count: 0,
            };
          });
        }
        state.form.tickets[ticketId] = {
          name: ticket.name,
          price: parseInt(ticket.price, 10),
          count: 0,
          vat_value: vatValue,
          subTickets,
        };
      });
    },
    setTourOptions(state, payload) {
      Object.assign(state.form.tours, {});
      const newTours = {};

      Object.keys(payload).forEach((tourId) => {
        newTours[tourId] = {
          name: payload[tourId].name,
          price: parseInt(payload[tourId].price, 10),
          count: 0,
          vat_value: parseFloat(payload[tourId].vat_value),
        };
      });
      state.form.tours = newTours;
    },
    setFormTourCount(state, { id, count }) {
      let c = count;
      if (typeof state.form.tours !== 'undefined') {
        if (Object.keys(state.form.tours).length > 0) {
          const newTourObj = state.form.tours; //TODO: this is not a new obj
          let maxParticipants = parseInt(state.data.single.max_participants, 10);
          if (typeof state.data.singleCalendar[state.form.date] !== 'undefined') {
            if (
              typeof state.data.singleCalendar[state.form.date][state.form.time] !== 'undefined'
            ) {
              if (
                parseInt(state.data.singleCalendar[state.form.date][state.form.time].tickets, 10) <
                maxParticipants
              ) {
                maxParticipants = parseInt(
                  state.data.singleCalendar[state.form.date][state.form.time].tickets,
                  10
                );
              }
              if (
                parseInt(state.data.single.single_ticketing, 10) === 1 &&
                parseInt(state.data.single.book_complete_tour, 10) === 1
              ) {
                maxParticipants = parseInt(
                  state.data.singleCalendar[state.form.date][state.form.time].tickets,
                  10
                );
              }
            }
          }
          if (!isNumeric(count)) {
            c = 0;
          }
          let totalCountMinusCurrent = 0;
          Object.keys(state.form.tours).forEach((key) => {
            if (parseInt(key, 10) !== parseInt(id, 10)) {
              totalCountMinusCurrent += state.form.tours[key].count;
            }
          });
          if (
            parseInt(state.data.single.single_ticketing, 10) === 0 &&
            parseInt(state.data.single.book_complete_tour, 10) === 1
          ) {
            let totalCount = 0;
            Object.keys(state.form.tours).forEach((tourId) => {
              if (tourId === id) {
                totalCount += count;
              } else {
                totalCount += state.form.tours[tourId].count;
              }
            });
            let hasSchedule = false;
            let tourCount = 0;
            if (typeof state.data.single.schedule !== 'undefined') {
              hasSchedule = true;
              tourCount = parseInt(state.data.single.schedule[state.form.time].tours, 10);
            }
            if (hasSchedule && totalCount <= tourCount) {
              newTourObj[id].count = count;
            }
          } else if (totalCountMinusCurrent + count > maxParticipants) {
            newTourObj[id].count = maxParticipants - totalCountMinusCurrent;
          } else {
            newTourObj[id].count = c;
            state.form.tours[id].count = c;
          }

          //state.form.tours = newTourObj;
        }
      }
    },
    setFormTicketCount(state, { id, count }) {
      let c = count;
      const ticketsObj = state.form.tickets;
      const maxParticipants =
        parseInt(state.data.single.max_participants, 10) * state.form.tourCount;
      if (!isNumeric(count)) {
        c = 0;
      }
      if (count > maxParticipants) {
        ticketsObj[id].count = maxParticipants;
      } else {
        ticketsObj[id].count = c;
      }
      if (typeof state.form.tickets[id].subTickets !== 'undefined') {
        const hasSubtickets = Object.keys(state.form.tickets[id].subTickets).find(
          (key) => state.form.tickets[id].subTickets[key].count !== 0
        );
        if (typeof hasSubtickets !== 'undefined') {
          ticketsObj[id].count = 1;
        } else {
          ticketsObj[id].count = 0;
        }
      }
    },
    setTotalTicketCount(state) {
      let count = 0;
      if (Object.keys(state.form.tickets).length > 0) {
        Object.keys(state.form.tickets).forEach((ticket) => {
          count += state.form.tickets[ticket].count;
        });
      }
      // case 2
      if (
        parseInt(state.data.single.single_ticketing, 10) === 0 &&
        parseInt(state.data.single.book_complete_tour, 10) === 1 &&
        Object.keys(state.data.single.priceList).length > 1
      ) {
        count = 0;
        if (Object.keys(state.form.tickets).length > 0) {
          Object.keys(state.form.tickets).forEach((ticketId) => {
            count += state.form.tickets[ticketId].count;
          });
        } else {
          Object.keys(state.form.tours).forEach((tourId) => {
            count += state.form.tours[tourId].count;
          });
        }
      }

      // case 3
      if (
        parseInt(state.data.single.single_ticketing, 10) === 1 &&
        parseInt(state.data.single.book_complete_tour, 10) === 0
      ) {
        count = 0;
        Object.keys(state.form.tickets).forEach((ticketId) => {
          const ticket = state.form.tickets[ticketId];
          if (typeof ticket.subTickets !== 'undefined') {
            Object.keys(ticket.subTickets).forEach((subTicketKey) => {
              const subTicket = ticket.subTickets[subTicketKey];
              count += subTicket.count;
            });
          } else {
            count += ticket.count;
          }
        });
      }

      if (Number.isNaN(count)) {
        count = 0;
      }
      state.form.ticketCount = count;
    },
    setTotalTourCount(state) {
      let count = 0;
      if (Object.keys(state.form.tours).length > 0) {
        Object.keys(state.form.tours).forEach((tour) => {
          count += state.form.tours[tour].count;
        });
      }
      // case 1

      let { priceList } = state.data.single;
      if (typeof state.form.time !== 'undefined') {
        if (typeof state.data.single.schedule !== 'undefined') {
          if (typeof state.data.single.schedule[state.form.time] !== 'undefined') {
            if (typeof state.data.single.schedule[state.form.time].priceList !== 'undefined') {
              priceList = state.data.single.schedule[state.form.time].priceList;
            }
          }
        }
      }
      if (typeof priceList !== 'undefined') {
        const priceListKeys = Object.keys(priceList);
        if (
          parseInt(state.data.single.single_ticketing, 10) === 0 &&
          parseInt(state.data.single.book_complete_tour, 10) === 1 &&
          priceListKeys.length === 1
        ) {
          if (state.form.tours[priceListKeys[0]].count === 0) {
            count = 1;
            Object.keys(state.form.tours).forEach((k) => {
              state.form.tours[k].count = 0;
            });
            state.form.tours[priceListKeys[0]].count = 1;
          }
        }
      }

      // case 2
      if (
        parseInt(state.data.single.single_ticketing, 10) === 0 &&
        parseInt(state.data.single.book_complete_tour, 10) === 1 &&
        Object.keys(priceList).length > 1
      ) {
        count = 0;
        Object.keys(state.form.tours).forEach((tourId) => {
          count += state.form.tours[tourId].count;
        });
      }

      state.form.tourCount = count;
    },
    setTotalPrice(state) {
      let { priceList } = state.data.single;
      if (typeof state.form.time !== 'undefined') {
        if (typeof state.data.single.schedule[state.form.time].priceList !== 'undefined') {
          priceList = state.data.single.schedule[state.form.time].priceList;
        }
      }

      let totalPrice = 0;
      const ticketIds = Object.keys(state.form.tickets);
      ticketIds.forEach((ticketId) => {
        const price =
          parseInt(state.form.tickets[ticketId].price, 10) * state.form.tickets[ticketId].count;
        totalPrice += price;
      });
      if (typeof priceList !== 'undefined') {
        let entryTicketPrice = parseInt(priceList[Object.keys(priceList)[0]].price, 10);
        if (parseInt(state.data.single.single_ticketing, 10) === 1) {
          entryTicketPrice *= state.form.ticketCount;
        }
        totalPrice += entryTicketPrice * state.form.tourCount;
      }

      // case 2
      if (
        parseInt(state.data.single.single_ticketing, 10) === 0 &&
        parseInt(state.data.single.book_complete_tour, 10) === 1 &&
        Object.keys(priceList).length > 1
      ) {
        totalPrice = 0;
        Object.keys(state.form.tours).forEach((tourId) => {
          totalPrice +=
            state.form.tours[tourId].count * parseInt(state.form.tours[tourId].price, 10);
        });
        ticketIds.forEach((ticketId) => {
          const price =
            parseInt(state.form.tickets[ticketId].price, 10) * state.form.tickets[ticketId].count;
          totalPrice += price;
        });
      }

      // case 3 and 4
      if (
        (parseInt(state.data.single.single_ticketing, 10) === 1 &&
          parseInt(state.data.single.book_complete_tour, 10) === 0) ||
        (parseInt(state.data.single.single_ticketing, 10) === 1 &&
          parseInt(state.data.single.book_complete_tour, 10) === 1)
      ) {
        totalPrice = 0;
        Object.keys(state.form.tours).forEach((tourId) => {
          totalPrice +=
            state.form.tours[tourId].count * parseInt(state.form.tours[tourId].price, 10);
        });
        ticketIds.forEach((ticketId) => {
          const price =
            parseInt(state.form.tickets[ticketId].price, 10) * state.form.tickets[ticketId].count;
          totalPrice += price;
        });
      }
      // subTickets
      ticketIds.forEach((ticketId) => {
        const { subTickets } = state.form.tickets[ticketId];
        if (typeof subTickets !== 'undefined') {
          Object.keys(subTickets).forEach((k) => {
            const subticket = subTickets[k];
            if (subticket.count > 0) {
              let priceSubTicket = 0;
              state.data.single.tickets[ticketId].subtickets.find((st) => {
                if (k === st.id) {
                  priceSubTicket = st.price;
                  return true;
                }
                return false;
              });
              totalPrice += priceSubTicket * subticket.count;
            }
          });
        }
      });

      state.form.totalPrice = totalPrice;
    },
    setFormDate(state, date) {
      state.form.date = date;
    },
    setFormTime(state, time) {
      state.form.time = time;
      if (typeof time !== 'undefined') {
        state.form.timeslotId = parseInt(
          state.data.singleCalendar[state.form.date][time].timeslotId,
          10
        );
      }
    },
    clearDateAndTime(state) {
      state.form.date = undefined;
      state.form.time = undefined;
    },
    clearForm(state) {
      const cleanObj = {
        date: undefined,
        time: undefined,
        tickets: {},
        tours: {},
        ticketCount: 0,
        tourCount: 0,
        totalPrice: 0,
        answers: {},
      };
      state.form = cleanObj;
    },
    setLanguageFilter(state, payload) {
      const lang = Object.keys(payload)[0];
      const val = payload[lang];
      if (val) {
        Object.keys(state.selectedLanguages).forEach((key) => {
          let newVal = false;
          if (key === lang) newVal = true;
          Object.assign(state.selectedLanguages[key], { selected: newVal });
        });
      }
    },
    setCategoryFilter(state, { id, selected }) {
      let index = null;
      state.categories.find((c, i) => {
        if (parseInt(id, 10) === parseInt(c.id, 10)) {
          index = i;
          return true;
        }
        return false;
      });
      state.categories[index].selected = selected;
    },
    setTourCategories(state, categories) {
      const newCategories = [];
      categories.forEach((category) => {
        newCategories.push({
          name: category.name,
          id: category.id,
          selected: false,
        });
      });
      state.categories = newCategories;
    },
    setSchedule(state, payload) {
      state.data.single.schedule = payload;
    },
    setSoldoutTimeslots(state, { data, date }) {
      let calendarTimes = {};
      if (state.data.singleCalendar[date] != null) {
        calendarTimes = state.data.singleCalendar[date];
      }
      Object.keys(data).forEach((t) => {
        if (data[t]) {
          calendarTimes[t] = { soldout: true, time: `${date} ${t}:00` };
        }
      });
      const orderedCalendarTime = Object.keys(calendarTimes)
        .sort()
        .reduce((obj, key) => {
          obj[key] = calendarTimes[key]; // eslint-disable-line
          return obj;
        }, {});
      state.data.singleCalendar[date] = orderedCalendarTime;
    },
    setVenues(state, guidedTours) {
      let hasMultipleVenues = false;
      const venues = {};
      guidedTours.forEach((t) => {
        venues[t.museumId] = { name: t.venue, selected: false };
      });
      if (Object.keys(venues).length > 1) {
        hasMultipleVenues = true;
      }
      state.hasMultipleVenues = hasMultipleVenues;
      state.venues = venues;
    },
    setVenueFilter(state, payload) {
      state.venues[Object.keys(payload)[0]].selected = payload[Object.keys(payload)[0]];
    },
  },
  actions: {
    clearEmptyCategories(context) {
      return new Promise((resolve) => {
        context.commit('clearEmptyCategories');
        resolve();
      });
    },
    getNextFewDays(context, params) {
      return new Promise((resolve) => {
        if (!context.state.hasLoadedNextFewDays) {
          context.dispatch('listPerDay', params).then(() => {
            context.commit('setNextFewDays');
            context.commit('setHasLoadedNextFewDays', true);
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    listPerDay(context, { day, lookahead }) {
      context.commit('isLoadingListPerday', true);
      return new Promise((resolve) => {
        const { app } = context.rootState;
        const endpoint = app.endpoints.GUIDED_TOURS_OVERVIEW;
        const url = `${app.API_URL}${context.rootState.language}/${endpoint}?date=${day}&lookahead=${lookahead}`;
        request.make(
          {
            type: 'GET',
            url,
          },
          (err, res) => {
            context.commit('isLoadingListPerday', false);
            context.commit('setToursPerDate', res.body.data);
            resolve();
          }
        );
      });
    },

    list(context) {
      return new Promise((resolve) => {
        context.commit('setLoadingList', true);
        const { app } = context.rootState;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS}`;

        request.make(
          {
            type: 'GET',
            url,
          },
          (err, res) => {
            context.commit('setLoadingList', false);
            if (res.status === 200) {
              const { tours } = res.body.data;
              if (typeof res.body !== 'undefined') {
                context.commit('setList', { payload: tours, language: context.rootState.language });
                if (typeof tours !== 'undefined') {
                  context.commit('setVenues', tours);
                }
                context.commit('setLoadedList', true);
                resolve();
              }
            }
          }
        );
      });
    },
    listCategories(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        request.make(
          {
            type: 'GET',
            url: `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_CATEGORY_LIST}`,
            data: null,
          },
          (err, res) => {
            if (res.status === 200) {
              context.commit('setTourCategories', res.body.data.category);
              resolve();
            }
          }
        );
      });
    },
    single(context, tour) {
      return new Promise((resolve) => {
        context.commit('setSingle', {});
        context.commit('setPriceListOrder');
        context.commit('setLoadingSingle', true);
        context.commit('setNotFoundSingle', false);
        const { app } = context.rootState;
        request.make(
          {
            type: 'GET',
            data: { tour },
            url: `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_SINGLE}`,
          },
          (err, res) => {
            context.commit('setLoadingSingle', false);
            if (res.status === 200) {
              if (typeof res.body !== 'undefined') {
                context.commit('setSingle', res.body.data);
                context.commit('setPriceListOrder');
                if (typeof res.body.data.tickets !== 'undefined') {
                  context.commit('setTicketOptions', res.body.data.tickets);
                  context.commit('setTourOptions', res.body.data.priceList);
                }
                if (typeof res.body.data.priceList !== 'undefined') {
                  context.commit('setTourOptions', res.body.data.priceList);
                }
              }
            } else {
              context.commit('setNotFoundSingle', true);
            }
            resolve();
          }
        );
      });
    },
    getSoldOut(context, { startDate, endDate }) {
      const { app } = context.rootState;
      const tour = context.state.data.single.id;
      request.make(
        {
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_SOLDOUT}${tour}/range/${startDate};${endDate}`,
        },
        (err, res) => {
          if (res.status === 200) {
            context.commit('setLoadedSoldOut', true);
            if (typeof res.body !== 'undefined') {
              context.commit('setSoldOut', res.body.data);
            }
          }
        }
      );
    },
    getSoldOutTimeSlots(context, { date }) {
      const tour = context.state.data.single.id;
      const { app } = context.rootState;
      const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_SOLDOUT_TIMESLOTS}${tour}/date/${date}`;
      request.make(
        {
          type: 'GET',
          url,
        },
        (err, res) => {
          if (res.status === 200) {
            context.commit('setSoldoutTimeslots', { data: res.body.data, date });
          }
        }
      );
    },
    setAnswer(context, payload) {
      context.commit('setAnswer', payload);
    },
    clearAnswers(context) {
      context.commit('clearAnswers');
    },
    setLanguageFilter(context, payload) {
      context.commit('setLanguageFilter', payload);
    },
    setCategoryFilter(context, payload) {
      context.commit('setCategoryFilter', payload);
    },
    setVenueFilter(context, payload) {
      context.commit('setVenueFilter', payload);
    },
    singleCalendar(context, { tour, date, forceDate }) {
      return new Promise((resolve) => {
        // Gets Calendar and Limits;
        context.commit('setSingleCalendar', {});
        context.commit('setLoadingSingleCalendar', true);
        context.commit('clearDateAndTime');
        context.commit('setPriceListOrder');
        context.commit('setLoadingSingleCalendarLimits', true);
        context.commit('setNotFoundSingleCalendar', false);
        const { app } = context.rootState;

        const currentDate = date.date();
        const startDate = date.startOf('month').format('YYYYMMDD');
        const endDate = date.endOf('month').format('YYYYMMDD');
        date.date(currentDate);
        context.commit('setLoadedSoldOut', false);
        // Calendar
        request.make(
          {
            type: 'GET',
            url: `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_SINGLE_CALENDAR}${tour}/range/${startDate};${endDate}`,
          },
          (err, res) => {
            context.commit('setLoadingSingleCalendar', false);
            if (res.status === 200) {
              context.dispatch('getSoldOut', { startDate, endDate });
              if (typeof res.body !== 'undefined') {
                context.commit('setSingleCalendar', res.body.data);
                context.commit('setLoadedSingleCalendar', true);
                // set first available date
                if (forceDate) {
                  const dateString = date.format('YYYY-MM-DD');
                  context.dispatch('setFormDate', dateString);
                } else if (Object.keys(res.body.data).length > 0) {
                  const dateString = date.format('YYYY-MM-DD');
                  if (typeof res.body.data[dateString] !== 'undefined') {
                    context.dispatch('setFormDate', dateString);
                  } else {
                    context.dispatch('setFormDate', Object.keys(res.body.data)[0]);
                  }
                }
                resolve();
              }
            } else {
              context.commit('setNotFoundSingleCalendar', true);
              resolve();
            }
          }
        );
      });
    },
    singleCalendarLimits(context, tour) {
      return new Promise((resolve) => {
        // limits
        const { app } = context.rootState;
        request.make(
          {
            type: 'GET',
            url: `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_SINGLE_LIMITS}${tour}`,
          },
          (err, res) => {
            if (res.status === 200) {
              if (typeof res.body !== 'undefined') {
                context.commit('setSingleCalendarLimits', res.body.data);
                context.commit('setLoadedSingleCalendarLimits', true);
                resolve();
              }
            }
            context.commit('setLoadingSingleCalendarLimits', false);
          }
        );
      });
    },
    clearSingleCalendar(context) {
      context.commit('clearSingleCalendar');
    },
    setFormTicketCount(context, payload) {
      context.commit('setFormTicketCount', payload);
      context.commit('setTotalTicketCount');

      context.commit('setTotalTourCount');
      context.commit('setTotalPrice');
    },
    setFormTourCount(context, payload) {
      context.commit('setFormTourCount', payload);

      context.commit('setTotalTourCount');
      context.commit('setTotalTicketCount');
      context.commit('setTotalPrice');
    },
    setFormDate(context, date) {
      context.commit('setFormDate', date);
      if (typeof date !== 'undefined') {
        context.dispatch('getTicketsForDay', date);
      }
      if (typeof context.state.data.singleCalendar[date] !== 'undefined') {
        const keys = Object.keys(context.state.data.singleCalendar[date]);
        if (keys.length === 1) {
          // There's just one time slot so we can pre-select it
          context.commit('setFormTime', keys[0]);
          context.commit('setPriceListOrder');
        } else if (typeof context.state.form.time !== 'undefined') {
          const r = keys.find((k) => k === context.state.form.time);
          if (typeof r !== 'undefined') {
            context.commit('setFormTime', r);
            context.commit('setPriceListOrder');
          } else {
            context.commit('setFormTime', keys[0]);
            context.commit('setPriceListOrder');
          }
        } else if (keys.length > 0) {
          context.dispatch('setFormTime', keys[0]);
          context.commit('setPriceListOrder');
        }
      }
    },
    setFormTime(context, time) {
      context.commit('setFormTime', time);
      context.commit('setTotalTourCount');
      context.commit('setPriceListOrder');
    },
    setSelectedDay(context, day) {
      context.commit('setSelectedDay', day);
    },
    addToCart(context) {
      return new Promise((resolve) => {
        const data = {};

        const priceListKeys = Object.keys(context.state.data.single.priceList);

        data.type = 'guidedTour';
        data.id = parseInt(context.state.data.single.priceList[priceListKeys[0]].id, 10);
        data.tourId = parseInt(context.state.data.single.id, 10);
        data.name = context.state.data.single.name;
        data.eventName = context.state.data.single.eventName;
        data.date = context.state.form.date;
        data.time = context.state.form.time;
        data.answers = Object.values(context.state.form.answers);
        data.tickets = [];
        data.tours = [];
        data.single_ticketing = false;
        if (parseInt(context.state.data.single.single_ticketing, 10) === 1) {
          data.single_ticketing = true;
        }
        data.book_complete_tour = false;
        if (parseInt(context.state.data.single.book_complete_tour, 10) === 1) {
          data.book_complete_tour = true;
        }
        data.totalCount = context.state.form.ticketCount;
        if (parseInt(context.state.data.single.booking_without_tickets, 10) === 1) {
          data.totalCount = context.state.form.tourCount;
        }
        data.price = null;
        data.priceId = null;
        if (priceListKeys.length === 1) {
          data.price = parseInt(context.state.data.single.priceList[priceListKeys[0]].price, 10);
          data.priceId = parseInt(priceListKeys[0], 10);
        }
        data.vat_value = parseFloat(
          context.state.data.single.priceList[priceListKeys[0]].vat_value
        );
        data.totalPrice = context.state.form.totalPrice;
        Object.keys(context.state.form.tickets).forEach((key) => {
          let subtickets;
          if (typeof context.state.form.tickets[key].subTickets !== 'undefined') {
            subtickets = [];
            let ticket = {};
            Object.keys(context.state.data.single.tickets).find((t) => {
              if (parseInt(context.state.data.single.tickets[t].id, 10) === parseInt(key, 10)) {
                ticket = context.state.data.single.tickets[t];
                return true;
              }
              return false;
            });
            Object.keys(context.state.form.tickets[key].subTickets).forEach((subticketKey) => {
              const subTicketData = ticket.subtickets.find(
                (el) => parseInt(el.id, 10) === parseInt(subticketKey, 10)
              );
              const { name } = subTicketData;
              const subticket = context.state.form.tickets[key].subTickets[subticketKey];

              let price = null;
              if (typeof subTicketData.price !== 'undefined') {
                price = parseInt(subTicketData.price, 10);
              }

              subtickets.push({
                id: subticketKey,
                count: subticket.count,
                price,
                name,
              });
            });
          }

          if (context.state.form.tickets[key].count !== 0) {
            const ticket = {
              id: parseInt(key, 10),
              count: context.state.form.tickets[key].count,
              name: context.state.form.tickets[key].name,
              price: parseInt(context.state.form.tickets[key].price, 10),
              vat_value: parseFloat(context.state.form.tickets[key].vat_value),
              subtickets,
            };
            data.tickets.push(ticket);
          }
        });
        Object.keys(context.state.form.tours).forEach((key) => {
          if (context.state.form.tours[key].count !== 0) {
            const tour = {
              id: parseInt(key, 10),
              count: context.state.form.tours[key].count,
              name: context.state.form.tours[key].name,
              price: parseInt(context.state.form.tours[key].price, 10),
              vat_value: parseFloat(context.state.form.tours[key].vat_value),
            };
            data.tours.push(tour);
          }
        });
        context.dispatch('cart/add', data, { root: true });
        resolve();
      });
    },
    clearForm(context) {
      return new Promise((resolve) => {
        context.commit('clearForm');
        resolve();
      });
    },
    getTicketsForDay(context, date) {
      const tour = context.state.data.single.id;
      if (typeof context.state.data.single.id !== 'undefined') {
        const { app } = context.rootState;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.GUIDED_TOURS_SINGLE}`;
        const type = 'GET';
        const data = {
          tour,
          date,
        };
        request.make({ type, data, url }, (err, res) => {
          if (err === null) {
            if (typeof res.body.data !== 'undefined') {
              context.commit('setTickets', res.body.data);
              if (typeof res.body.data.tickets !== 'undefined') {
                context.commit('setTicketOptions', res.body.data.tickets);
              } else {
                context.commit('setTicketOptions', {});
              }
            } else {
              context.commit('setTickets', { tickets: {} });
              context.commit('setTicketOptions', {});
            }
            if (typeof res.body.data.schedule !== 'undefined') {
              context.commit('setSchedule', res.body.data.schedule);
              context.commit('setPriceListOrder');
              if (
                ['devbms', 'bms', 'nm', 'smk', 'devsmk', 'devnm'].includes(
                  import.meta.env.VITE_SITE_FRONT_ID
                )
              ) {
                context.dispatch('getSoldOutTimeSlots', { date });
              }
            }
          }
          context.commit('setTotalTourCount');
          context.commit('setTotalPrice');
        });
      }
    },
    setFormSubTicketCount(context, data) {
      context.commit('setSubTicketCount', data);
      context.commit('setFormTicketCount', { id: data.ticketId });
      context.commit('setTotalTicketCount');
      context.commit('setTotalPrice');
    },
  },
  getters: {
    isAvailable: (state) => {
      if (
        state.data.singleCalendarLimits.first === null &&
        state.data.singleCalendarLimits.last === null
      ) {
        return false;
      }
      return true;
    },
    isSoldOut: (state) => {
      if (typeof state.data.soldOutDates[state.form.date] === 'undefined') {
        return false;
      }
      return state.data.soldOutDates[state.form.date];
    },
    isAvailableToday: (state) => {
      if (!Object.keys(state.data.singleCalendar).includes(state.form.date)) {
        return false;
      }
      return true;
    },
    nextAvailableDay: (state) => {
      if (typeof state.form.date === 'undefined') {
        return null;
      }
      const nextAvailableDate = Object.keys(state.data.singleCalendar).find((c) => {
        if (state.form.date > c) {
          return false;
        }
        return true;
      });
      if (typeof nextAvailableDate === 'undefined') {
        return null;
      }
      return nextAvailableDate;
    },
    filteredList: (state) => {
      const enabledCategories = state.categories.filter((category) => category.selected);
      const enabledCategoryIds = [];
      enabledCategories.forEach((c) => {
        enabledCategoryIds.push(c.id);
      });
      const list = {};

      const { hasMultipleVenues } = state;
      const enabledVenues = Object.keys(state.venues).filter((k) => state.venues[k].selected);

      Object.keys(state.data.list).forEach((key) => {
        const tour = state.data.list[key];
        let shouldAdd = true;
        if (enabledCategories.length !== 0) {
          shouldAdd = false;
          tour.custom_groups.find((cg) => {
            if (enabledCategoryIds.includes(cg.id)) {
              shouldAdd = true;
              return true;
            }
            return false;
          });
        }

        const lang = tour.langIdentifier.toLowerCase();
        if (!state.selectedLanguages[lang].selected) {
          shouldAdd = false;
        }
        if (hasMultipleVenues && enabledVenues.length > 0) {
          if (!enabledVenues.includes(tour.museumId)) {
            shouldAdd = false;
          }
        }

        const allToursPerDate = [];
        Object.keys(state.data.toursPerDate).forEach((k) => {
          state.data.toursPerDate[k].forEach((id) => {
            if (!allToursPerDate.includes(id)) {
              allToursPerDate.push(id);
            }
          });
        });
        if (shouldAdd && state.selectedDay !== null) {
          if (typeof state.data.toursPerDate[state.selectedDay] === 'undefined') {
            shouldAdd = false;
          } else if (!allToursPerDate.includes(tour.id)) {
            shouldAdd = false;
          }
        }

        if (shouldAdd) {
          list[key] = tour;
        }
      });

      return list;
    },

    isLessTicketsThanTours(state) {
      if (typeof state.data.single.tickets === 'undefined') {
        return false;
      }
      if (Object.keys(state.data.single.tickets).length === 0) {
        return false;
      }
      if (
        parseInt(state.data.single.book_complete_tour, 10) === 0 &&
        parseInt(state.data.single.booking_without_tickets, 10) === 0
      ) {
        if (state.form.ticketCount < state.form.tourCount) {
          return true;
        }
      } else if (
        parseInt(state.data.single.book_complete_tour, 10) === 1 &&
        parseInt(state.data.single.single_ticketing, 10) === 1
      ) {
        // workshops
        if (state.form.ticketCount < state.form.tourCount) {
          return true;
        }
      }
      return false;
    },
    isMoreTicketsThanTours(state) {
      if (typeof state.data.single.tickets === 'undefined') {
        return false;
      }
      if (Object.keys(state.data.single.tickets).length === 0) {
        return false;
      }
      if (
        parseInt(state.data.single.book_complete_tour, 10) === 0 &&
        parseInt(state.data.single.booking_without_tickets, 10) === 0
      ) {
        if (state.form.ticketCount > state.form.tourCount) {
          return true;
        }
      } else if (
        parseInt(state.data.single.book_complete_tour, 10) === 1 &&
        parseInt(state.data.single.single_ticketing, 10) === 1
      ) {
        // workshops
        if (state.form.ticketCount > state.form.tourCount) {
          return true;
        }
      }
      return false;
    },
  },
};
