import { createRouter, createWebHistory } from 'vue-router';
import { setPageTitle } from '../lib/pageTitle';

const NotFound = () => import('../components/NotFound.vue');
const Language = () => import('../components/Language/Language.vue');
const Home = () => import('../components/Home/Home.vue');
const Merchandise = () => import('../components/Merchandise/Merchandise.vue');
const VoucherList = () => import('../components/Vouchers/List.vue');
const VoucherSingle = () => import('../components/Vouchers/Single.vue');
const EventListOrSingle = () => import('../components/Tickets/EventListOrSingle.vue');
const Event = () => import('../components/Tickets/Event.vue');
const GuidedToursList = () => import('../components/GuidedTours/GuidedToursList.vue');
const GuidedToursSingle = () => import('../components/GuidedTours/GuidedToursSingle.vue');
const UserLogin = () => import('../components/User/Login.vue');
const UserRegister = () => import('../components/User/Register.vue');
const GuestRegister = () => import('../components/User/GuestRegister.vue');
const UserRegisterSuccess = () => import('../components/User/RegisterSuccess.vue');
const UserForgot = () => import('../components/User/Forgot.vue');
const UserRecover = () => import('../components/User/Recover.vue');
const UserForgotSuccess = () => import('../components/User/ForgotSuccess.vue');
const UserRecoverSuccess = () => import('../components/User/RecoverSuccess.vue');
const UserSettings = () => import('../components/User/Settings.vue');
const Product = () => import('../components/Product/Product.vue');
const Checkout = () => import('../components/Checkout/Checkout.vue');
const CheckoutPaymentAccepted = () => import('../components/Checkout/PaymentAccepted.vue');
const CheckoutPaymentCanceled = () => import('../components/Checkout/PaymentCanceled.vue');
const CheckoutSuccess = () => import('../components/Checkout/CheckoutSuccess.vue');
const Static = () => import('../components/Static/Static.vue');
const ShippingInfo = () => import('../components/ShippingInfo/ShippingInfo.vue');
const Logout = () => import('../components/User/Logout.vue');

/*const languages = [];
JSON.parse(import.meta.env.VITE_LANGUAGES).forEach((language) => {
  languages.push(language.name.substring(0, 2));
});
const languagesString = languages.join('|');
*/

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    if (window.vue.$store.state.waitingForServer.waiting) {
      setTimeout(() => {
        scrollBehavior(to, from, savedPosition);
      }, 500);
    } else {
      window.scrollTo(savedPosition.x, savedPosition.y);
    }
  } else {
    window.scrollTo(0, 0);
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior,
  routes: [
    { path: '/logout', name: 'logout', component: Logout },
    {
      //path: `/:lang(${languagesString})?`,
      path: `/:lang(.*)?`,
      component: Language,
      children: [
        {
          path: `/:lang(.*)?`,
          component: Home,
          name: 'home',
          meta: { title: '' },
        },
        {
          path: 'merchandise',
          component: Merchandise,
          name: 'merchandise',
          meta: { title: 'MERCHANDISE_PAGE_TITLE' },
        },
        {
          path: 'merchandise/:category',
          component: Merchandise,
          name: 'mechandiseCategory',
          meta: { title: 'MERCHANDISE_CATEGORY_PAGE_TITLE' },
        },
        {
          path: 'passes',
          component: Merchandise,
          name: 'passes',
          meta: { title: 'NAV_MEMCARD' },
        },
        {
          path: 'passes/:id',
          component: Product,
          name: 'pass',
          meta: { title: 'NAV_MEMCARD' },
        },
        {
          path: 'vouchers',
          component: VoucherList,
          name: 'voucherList',
          meta: { title: 'VOUCHER_PAGE_TITLE' },
        },
        {
          path: 'vouchers/:id',
          component: VoucherSingle,
          name: 'voucherSingle',
          meta: { title: 'VOUCHER_PAGE_TITLE' },
        },
        {
          path: 'tickets',
          component: EventListOrSingle,
          name: 'tickets',
          meta: { title: 'TICKETS_PAGE_TITLE' },
        },
        {
          path: 'tickets/:id',
          component: Event,
          name: 'ticket',
          meta: { title: 'TICKETS_PAGE_TITLE' },
        },
        {
          path: 'guided-tours',
          component: GuidedToursList,
          name: 'guidedTours',
          meta: { title: 'GUIDED_TOURS_PAGE_TITLE' },
        },
        {
          path: 'guided-tours/:id',
          component: GuidedToursSingle,
          name: 'guidedTour',
          meta: { title: 'GUIDED_TOUR_SINGLE_PAGE_TITLE' },
        },
        {
          path: 'login',
          name: 'login',
          component: UserLogin,
          meta: { title: 'USER_LOGIN_PAGE_TITLE' },
        },
        {
          path: 'register/success',
          name: 'registerSuccess',
          component: UserRegisterSuccess,
          meta: { title: 'USER_REGISTER_SUCCESS_PAGE_TITLE' },
        },
        {
          path: 'register',
          name: 'register',
          component: UserRegister,
          meta: { title: 'USER_REGISTER_PAGE_TITLE' },
        },
        {
          path: 'register-guest',
          component: GuestRegister,
          name: 'guestRegister',
          meta: { title: 'GUEST_REGISTER_PAGE_TITLE' },
        },
        {
          path: 'forgot-password',
          component: UserForgot,
          name: 'forgotPassword',
          meta: { title: 'USER_FORGOT_PAGE_TITLE' },
        },
        {
          path: 'recover/:guid',
          component: UserRecover,
          name: 'recoverAccount',
          meta: { title: 'USER_RECOVER_PAGE_TITLE' },
        },
        {
          path: 'password-reset-success',
          component: UserForgotSuccess,
          name: 'forgotPasswordSuccess',
          meta: { title: 'USER_FORGOT_SUCCESS_PAGE_TITLE' },
        },
        {
          path: 'recover-success',
          component: UserRecoverSuccess,
          name: 'recoverPasswordSuccess',
          meta: { title: 'AUTH_RECOVER_SUCCESS_PAGE_TITLE' },
        },
        {
          path: 'settings',
          component: UserSettings,
          name: 'settings',
          meta: { title: 'AUTH_USER_SETTINGS_PAGE_TITLE' },
        },
        {
          path: 'product/:id',
          component: Product,
          name: 'product',
          meta: { title: 'PRODUCT_PAGE_TITLE' },
        },
        {
          path: 'checkout',
          component: Checkout,
          name: 'checkout',
          meta: { title: 'CHECKOUT_PAGE_TITLE' },
        },
        {
          path: 'checkout/accepted',
          component: CheckoutPaymentAccepted,
          name: 'checkoutAccepted',
          meta: { title: 'CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE' },
          props: { paymentAccepted: true },
        },
        {
          path: 'checkout/canceled',
          component: CheckoutPaymentCanceled,
          name: 'checkoutCanceled',
          meta: { title: 'CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE' },
        },
        {
          path: 'checkout/success',
          component: CheckoutSuccess,
          name: 'checkoutSuccess',
          meta: { title: 'CHECKOUT_SUCCESS_PAGE_TITLE' },
        },
        {
          path: 'checkout/failed',
          component: Checkout,
          name: 'checkoutFailed',
          meta: { title: 'CHECKOUT_PAGE_TITLE' },
          props: { paymentFailed: true },
        },
        {
          path: 'page/shipping',
          component: ShippingInfo,
          name: 'shippingInfo',
          meta: { title: '' },
        },
        {
          path: 'page/:id',
          component: Static,
          name: 'page',
          meta: { title: '' },
        },
        { path: ':pathMatch(.*)*', name: 'pageNotFound', component: NotFound },
      ],
    },
    //{ path: '/:pathMatch(.*)*', name: 'pageNotFound', component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  if (from.fullPath !== '/') {
    window.vue.$store.dispatch('history/setLastVisitedUrl', from.fullPath);
  }
  router.previousPageTitle = from.meta.title;

  const userPaths = [
    'login',
    'registerSuccess',
    'register',
    'guestRegister',
    'forgotPassword',
    'recoverAccount',
    'forgotPasswordSuccess',
    'recoverPasswordSuccess',
  ];
  if (from.name === 'checkout' && userPaths.includes(to.name)) {
    if (typeof window.vue !== 'undefined') {
      window.vue.$store.dispatch('user/setRedirectionRoute', from);
    }
  } else if (typeof window.vue !== 'undefined' && !userPaths.includes(to.name)) {
    window.vue.$store.dispatch('user/setRedirectionRoute', null);
  }
  next();
});
router.afterEach((to) => {
  let title = '';

  if (typeof window.vue !== 'undefined') {
    if (to.meta.title !== '') {
      title = window.vue.$t(to.meta.title);
    }
    setPageTitle(title);
  }
});

export default router;
