<template>
  <div class="footerContent">
    <div class="copyright">© {{ currentYear() }} Visitate GmbH & Co. KG</div>
    <nav class="footerLinks">
      <ul v-if="!customTermsLinks">
        <li>
          <router-link :to="{ name: 'page', params: { id: 'imprint', lang: language } }">
            {{ $t('FOOTER_IMPRINT_LINK') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'page', params: { id: 'terms', lang: language } }">
            {{ $t('FOOTER_TERMS_LINK_GTC') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'page', params: { id: 'data_protection', lang: language } }">
            {{ $t('FOOTER_TERMS_LINK_DATA_PROTECTION') }}
          </router-link>
        </li>
        <li v-if="hasMerchandise">
          <router-link :to="{ name: 'shippingInfo', params: { lang: language } }">
            {{ $t('FOOTER_SHIPPING') }}
          </router-link>
        </li>
      </ul>
      <ul v-else>
        <li v-if="customTermsLinks.imprint">
          <a :href="customTermsLinks.imprint.url" target="_blank">{{
            customTermsLinks.imprint.title
          }}</a>
        </li>
        <li v-if="customTermsLinks.terms">
          <a :href="customTermsLinks.terms.url" target="_blank">{{
            customTermsLinks.terms.title
          }}</a>
        </li>
        <li v-if="customTermsLinks.data_protection">
          <a :href="customTermsLinks.data_protection.url" target="_blank">{{
            customTermsLinks.data_protection.title
          }}</a>
        </li>
        <li v-if="customTermsLinks.shipping && hasMerchandise">
          <a :href="customTermsLinks.shipping.url" target="_blank">{{
            customTermsLinks.shipping.title
          }}</a>
        </li>
      </ul>
    </nav>
    <template v-if="socialLinks">
      <div v-if="socialLinks.length > 0" class="social">
        <a :href="sl.url" target="_blank" v-for="sl in socialLinks" :icon="sl.name" :key="sl.name">
          <Icon class="socialIcon" :icon="sl.name" />
        </a>
      </div>
    </template>
    <div class="sponsors" v-if="partnerLogos.length > 0">
      <div class="sponsorGroup" v-for="(partnerGroup, index) in partnerLogos" :key="index">
        <span class="title" v-if="partnerGroup.name[language]"
          >{{ partnerGroup.name[language] }}:</span
        >
        <div class="logos">
          <div class="logo" v-for="logo in partnerGroup.logos" :key="logo.image">
            <img v-if="!logo.url" :src="'/static' + logo.image" />
            <a v-else :href="logo.url" target="_blank"><img :src="'/static' + logo.image" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Icon from '../Icon.vue';

export default {
  name: 'FooterComponent',
  components: { Icon },
  data() {
    return {
      currentYear() {
        const d = new Date().getFullYear();
        return d;
      },
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
      socialLinks: (state) => state.footer.data.socialLinks,
      partnerLogos: (state) => state.app.partnerLogos,
      hasMerchandise: (state) => state.app.hasMerchandise,
    }),
    customTermsLinks() {
      const customLinks = this.$store.state.app.customTermsLinks;
      if (customLinks === false || customLinks === 'false' || customLinks === '0') {
        return false;
      }
      return customLinks[this.language];
    },
  },
  methods: {
    ...mapActions({
      getContents: 'footer/get',
    }),
  },
  mounted() {
    this.getContents();
  },
};
</script>

<style lang="scss" scoped>
.footerContent {
  padding: 2 * $sp;
  background: $footer-background-color;
  color: $footer-color;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2 * $sp;
  //so that we have enough space for the buy button on tickets page
  @media screen and (max-width: $screen-xs) {
    padding-bottom: 100px;
  }
}
.footerLinks {
  ul {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      margin-left: 0.5 * $sp;
      margin-right: 0.5 * $sp;
      a {
        color: $footer-color;
      }
    }
  }
}
.copyright {
  text-align: center;
}
.social {
  flex-grow: 0;
  text-align: center;
  .socialIcon {
    padding-left: calc($sp / 2);
    padding-right: calc($sp / 2);
  }
}
.copyright,
.footerLinks,
.social {
  min-width: calc((100% / 3) - #{2 * $sp});
}
.sponsors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2 * $sp;
  .sponsorGroup {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $sp;
  }
  .title {
    padding-right: $sp;
    padding-bottom: $sp;
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .logo img {
    height: 80px;
    margin-left: $sp * 2;
    margin-bottom: 2 * $sp;
  }
}
</style>
